// import 'mdb-ui-kit/js/mdb.umd.min';
// import 'mdb-ui-kit/js/mdb.es.min';
import * as mdb from 'mdb-ui-kit';
import {
    Button,
    Carousel,
    Collapse,
    Offcanvas,
    Dropdown,
    Input,
    Popover,
    ScrollSpy,
    Ripple,
    Tab,
    Tooltip,
    Range,
    Alert,
    Animate,
    Datepicker,
    Datatable,
    Lightbox,
    Navbar,
    Popconfirm,
    Rating,
    Sidenav,
    SmoothScroll,
    Timepicker,
    Toast,
    InfiniteScroll,
    LazyLoad,
    Stepper,
    Sticky,
    Select,
    Touch,
    PerfectScrollbar,
    Loading,
    Autocomplete,
    Clipboard,
    ChipsInput,
    Chip,
    MultiRangeSlider,
    Datetimepicker,
    Modal
} from 'mdb-ui-kit';

window.mdb = mdb;
mdb.initMDB({
    Button,
    Carousel,
    Collapse,
    Offcanvas,
    Dropdown,
    Input,
    Popover,
    ScrollSpy,
    Ripple,
    Tab,
    Tooltip,
    Range,
    Alert,
    Animate,
    Datepicker,
    Datatable,
    Lightbox,
    Navbar,
    Popconfirm,
    Rating,
    Sidenav,
    SmoothScroll,
    Timepicker,
    Toast,
    InfiniteScroll,
    LazyLoad,
    Stepper,
    Sticky,
    Select,
    Touch,
    PerfectScrollbar,
    Loading,
    Autocomplete,
    Clipboard,
    ChipsInput,
    Chip,
    MultiRangeSlider,
    Datetimepicker,
    Modal
});

